
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub.tsx'
import {AsideMenuItem} from './AsideMenuItem.tsx'
import {useTranslation} from "react-i18next";

export function AsideMenuMy() {
  const {t} = useTranslation()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='home'
        title={t('Dashboard')}
        // fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/scheduled'
        icon='calendar-add'
        title={t('Scheduled Transactions')}
        // fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/accounts'
        icon='wallet'
        title={t('My Accounts')}
        // fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/transactions'
        icon='cube-2'
        title={t('My Transactions')}
        // fontIcon='bi-layers'
      />
    </>
  )
}
