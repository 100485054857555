import {Route, Routes} from 'react-router-dom'
import {SignUp} from './components/SignUp.tsx'
import {ForgotPassword} from './components/ForgotPassword.tsx'
import Login from './components/Login.tsx'
import Email from './components/Email.tsx'
import {AuthLayout} from './AuthLayout.tsx'
import ReAuthenticate from "./components/ReAuthenticate.tsx";
import {ResetPassword} from "./components/ResetPassword.tsx";
import {setAuthUrl} from "./core/AuthHelpers.ts";
import axios from "axios";
import {setUser} from "../../store/auth.ts";
import {useAppDispatch} from "../../store/hooks.ts";
import Phone from "./components/Phone.tsx";
import {useTranslation} from "react-i18next";
import SentryRoutes from "../../../_mesomb/helpers/SentryRoutes.ts";
import TwoFactorAuth from "./components/TwoFactorAuth.tsx";
import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {boolean} from "yup";
import {Helmet} from "react-helmet";
import VerifyEmail from "./components/VerifyEmail.tsx";

const AuthPage = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [twoFactor, setTwoFactor] = useState<boolean>(false)
  const [verifyEmail, setVerifyEmail] = useState<boolean>(false)

  const onLogin = async (flow: string, params: Record<string, any>, setErrors) => {
    let endpoint: string = setAuthUrl('auth/login')
    switch (flow) {
      case 'login':
        break;
      case 'sendEmailCode':
        endpoint = setAuthUrl('auth/code/request')
        break;
      case 'confirmEmailCode':
        endpoint = setAuthUrl('auth/code/confirm')
        break;
      case 'verifyEmailCode':
        endpoint = setAuthUrl('auth/email/verify')
        break;
      case 'sendPhoneCode':
        endpoint = setAuthUrl('auth/phone/request')
        break;
      case 'confirmPhoneCode':
        endpoint = setAuthUrl('auth/phone/confirm')
        break;
      case 'google':
      case 'apple':
      case 'facebook':
        endpoint = setAuthUrl('auth/provider/token')
        params = {
          'provider': flow,
          'process': 'login',
          'token': params
        }
        break;
      case 'mfa':
        endpoint = setAuthUrl('auth/2fa/authenticate')
        break;
      case 'signup':
        endpoint = setAuthUrl('auth/signup')
        break;
    }
    let ret;
    try {
      ret = await axios.post(endpoint, params)
    } catch (error) {
      if (error.response) {
        ret = error.response
      }
    }

    if (ret?.data?.errors && setErrors) {
      setErrors(ret.data.errors.reduce((acc, item) => ({...acc, [item.param]: item.message}), {}))
      return
    }

    if (!ret?.data?.meta) {
      throw Error(t('Error during the login process please try again or contact the support'))
    }

    const {meta, data: {user, flows}} = ret.data;
    if (meta) {
      if (!meta.is_authenticated) {
        const pending = flows.find(f => f.is_pending);
        if (pending?.id === 'provider_signup') {
          endpoint = setAuthUrl('auth/provider/signup');
          // await axios.post(endpoint, {email: extra.social.email}, 'post', Restfull.authToken);
        }
        if (['login_by_code', 'login_by_phone'].includes(pending?.id)) {
          return;
        }
        if (pending?.id === 'mfa_authenticate') {
          setTwoFactor(true);
          return;
        }
        if (pending?.id === 'verify_email') {
          setVerifyEmail(true);
          return;
        }
      }
    }
    if (meta?.is_authenticated === true) {
      dispatch(setUser(user))
      location.href = '/'
    } else {
      throw Error(t('Error during the login process please try again or contact the support'))
    }
  }

  return (
    <>
      <SentryRoutes>
        <Route element={<AuthLayout />}>
          <Route path='login' element={<Login onLogin={onLogin} />} />
          <Route path='email' element={<Email onLogin={onLogin} />} />
          <Route path='phone' element={<Phone onLogin={onLogin} />} />
          <Route path='signup' element={<SignUp onLogin={onLogin} />} />
          {/*<Route path='reauthenticate' element={<ReAuthenticate />} />*/}
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='reset-password/:key' element={<ResetPassword />} />
          {/*<Route path='confirm-email/:key' element={<EmailVerification />} />*/}
          <Route index element={<Login onLogin={onLogin} />} />
        </Route>
      </SentryRoutes>
      <Modal dialogClassName={`modal-dialog modal-dialog-centered mw-500px`} show={twoFactor}>
        <Modal.Body>
          <TwoFactorAuth onSubmit={async (code) => {
            await onLogin('mfa', {code})
          }} />
        </Modal.Body>
      </Modal>
      <Modal dialogClassName={`modal-dialog modal-dialog-centered mw-500px`} show={verifyEmail}>
        <Modal.Body>
          <VerifyEmail onSubmit={async (code) => {
            await onLogin('verifyEmailCode', {key: code})
          }} />
        </Modal.Body>
      </Modal>
      <Helmet>
        <title>{t('Authentication')}</title>
      </Helmet>
    </>
  )
}

export {AuthPage}
