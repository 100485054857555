import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {AccountModel} from "../modules/payment/core/_models.ts";

export interface TermsSliceState {
  terms?: Record<string, any>
}

const initialState: TermsSliceState = {
  terms: null,
}

export const termsSlice = createAppSlice({
  name: 'terms',
  initialState,
  reducers: create => ({
    setTerms: create.reducer((state, action: PayloadAction<Record<string, any>>) => {
      state.terms = action.payload
    }),
  }),
  selectors: {
    selectTerms: terms => terms.terms,
  },
});

export const { setTerms } =
  termsSlice.actions

export const { selectTerms } = termsSlice.selectors
