import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { combineSlices, configureStore } from "@reduxjs/toolkit"
import {RootState, setupListeners} from "@reduxjs/toolkit/query"
import {authSlice} from "./auth.ts";
import {applicationSlice} from "./application.ts";
import {configsSlice} from "./configs.ts";
import * as Sentry from "@sentry/react";
import {termsSlice} from "./terms.ts";
import {announcementsSlice} from "./announcements.ts";

const rootReducer = combineSlices(authSlice, applicationSlice, configsSlice, termsSlice, announcementsSlice)

export const makeStore = (preloadedState?: Partial<RootState>) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });

  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware() //.concat(quotesApiSlice.middleware)
    },
    preloadedState,
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
