import React, {useMemo} from 'react';
import I18n from "../../../../_mesomb/i18n";
import {toAbsoluteStatic} from "../../../../_mesomb/helpers";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import i18n from "../../../../_mesomb/i18n";
import FormBuilder from "../../../../_mesomb/partials/form/FormBuilder.tsx";
import ModalFormBuilder from "../../../../_mesomb/partials/components/ModalFormBuilder.tsx";
import {FormFieldProps} from "../../../../_mesomb/partials/form/FormField.tsx";

export const fields: Array<FormFieldProps> = [
  {
    type: 'select',
    name: 'legal_form',
    label: i18n.t('Legal Form'),
    options: [
      {value: 'SARL', label: 'Société à Responsabilité Limitée'},
      {value: 'SA', label: 'Société Anonyme'},
      {value: 'SAS', label: 'Société par Actions Simplifiées'},
      {value: 'ETS', label: 'Établissement'},
      {value: 'OTHER', label: i18n.t('Other Legal Form')},
    ]
  },
  {
    type: 'text',
    name: 'name',
    label: i18n.t('Name'),
    // placeholder: i18n.t('The public name of your service')
  },
  {
    name: 'contact_email',
    label: I18n.t('Contact Email'),
    type: 'email',
  },
  {
    name: 'contact_phone',
    label: I18n.t('Phone Number'),
    type: 'phone',
  },
  {
    type: 'text',
    name: 'trade_register_number',
    label: 'Trade Register Number',
  },
  {
    type: 'text',
    name: 'taxpayer_card_number',
    label: 'Tax Payer Card Number',
  },
  {
    name: 'address',
    label: I18n.t('Address'),
    type: 'address',
  },
  {
    name: 'logo',
    label: i18n.t('Logo'),
    title: i18n.t('Upload the logo of your company'),
    type: 'dropzone',
    col: 2,
  },
  {
    name: 'trade_register',
    label: i18n.t('Trade Register'),
    title: i18n.t('Upload the trade register'),
    type: 'dropzone',
    col: 2,
  },
  {
    name: 'taxpayer_card',
    label: i18n.t('Unique Tax Identification'),
    title: i18n.t('Upload your NIU'),
    type: 'dropzone',
    col: 2,
  },
];

export const formSchema = Yup.object().shape({
  legal_form: Yup.string().required(i18n.t('Field_is_required')),
  name: Yup.string().required(i18n.t('Field_is_required')),
  contact_email: Yup.string().email(i18n.t('Invalid email address')).required(i18n.t('Field_is_required')),
  contact_phone: Yup.string().required(i18n.t('Invalid email address')),
  trade_register_number: Yup.string().required(i18n.t('Field_is_required')),
  taxpayer_card_number: Yup.string().required(i18n.t('Field_is_required')),
  logo: Yup.string().required(i18n.t('Field_is_required')),
  trade_register: Yup.string().required(i18n.t('Field_is_required')),
  taxpayer_card: Yup.string().required(i18n.t('Field_is_required')),
  // b_party: Yup.string().matches(/^[0-9]{8,9}$/, i18n.t('Invalid_phone_number')).required(i18n.t('Field_is_required')),
})

function BusinessForm({asModal, show, handleClose, action, onSubmit, profile, ...props}) {
  const Component = asModal ? ModalFormBuilder : FormBuilder
  fields[7].file = profile.business?.logo
  fields[8].file = profile.business?.trade_register
  fields[9].file = profile.business?.taxpayer_card

  return (
    <Component
      show={show}
      handleClose={handleClose}
      fields={fields}
      formSchema={formSchema}
      className={''}
      action={action}
      onSubmit={onSubmit}
      initialValues={{
        legal_form: profile!.business?.legal_form || 'SARL',
        name: profile!.business?.name,
        contact_email: profile!.business?.contact_email,
        contact_phone: profile!.business?.contact_phone,
        trade_register_number: profile!.business?.trade_register_number,
        taxpayer_card_number: profile!.business?.taxpayer_card_number,
        logo: profile!.business?.logo?.id,
        trade_register: profile!.business?.trade_register?.id,
        taxpayer_card: profile!.business?.taxpayer_card?.id,
      }}
      nbrCols={2}
      {...props}
    />
  );
}

export default BusinessForm;