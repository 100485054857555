import React, {useMemo} from 'react';
import I18n from "../../../../_mesomb/i18n";
import {toAbsoluteStatic} from "../../../../_mesomb/helpers";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import i18n from "../../../../_mesomb/i18n";
import FormBuilder from "../../../../_mesomb/partials/form/FormBuilder.tsx";
import ModalFormBuilder from "../../../../_mesomb/partials/components/ModalFormBuilder.tsx";
import banks from '../../../../_mesomb/assets/data/banks.json'
import {FormFieldProps} from "../../../../_mesomb/partials/form/FormField.tsx";

export const fields: Array<FormFieldProps> = [
  {
    type: 'bank_rib',
    name: 'bank_rib',
    label: i18n.t('Bank RIB'),
  },
  {
    name: 'bank',
    placeholder: I18n.t('Select the bank'),
    type: 'select',
    label: i18n.t('Bank'),
    options: banks
  },
  {
    type: 'text',
    name: 'name',
    label: i18n.t('Name'),
    placeholder: i18n.t('Label of your account')
  },
];

export const formSchema = Yup.object().shape({
  bank_rib: Yup.object({
    bank_code: Yup.string().required(i18n.t('Field_is_required')),
    agence_code: Yup.string().required(i18n.t('Field_is_required')),
    account_number: Yup.string().required(i18n.t('Field_is_required')).matches(/^[0-9]*$/, i18n.t('Invalid account number')),
    rib_key: Yup.string().required(i18n.t('Field_is_required')),
  }),
  name: Yup.string().required(i18n.t('Field_is_required')),
  bank: Yup.string().required(i18n.t('Field_is_required')),
})

function BankAccountForm({asModal, show, handleClose, action, onSubmit, selected, ...props}) {
  const {t} = useTranslation()
  const Component = asModal ? ModalFormBuilder : FormBuilder
  return (
    <Component
      show={show}
      handleClose={handleClose}
      fields={fields}
      formSchema={formSchema}
      className={''}
      action={action}
      onSubmit={onSubmit}
      initialValues={{
        bank_rib: {
          bank_code: selected?.bank_code || '',
          agence_code: selected?.agence_code || '',
          account_number: selected?.account_number || '',
          rib_key: selected?.rib_key || '',
        },
        bank: selected?.bank || '',
        name: selected?.name || '',
      }}
      {...props}
    />
  );
}

export default BankAccountForm;
